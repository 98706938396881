import React, { useRef, useEffect, memo, useState } from "react";
import s from "./index.module.less";
import { data } from "./data";
import { Collapse } from "antd";
import CustomIcon from "../CustomIcon";

const AttrVert = ({

}) => {
    const [activeItem, setActiveItem] = useState(0)
    const [items, setItems] = useState([])
    const getItems = () => {

        return data?.map((v, i) => {
            let maxLen = 0;
            v?.children?.map(item=>{
                if(item?.key?.length>maxLen){
                    maxLen = item?.key?.length
                }
            })
            //console.log(maxLen);
            return ({
                key: i,
                label: <div className={`${s.title}`}>
                    {v?.label}
                </div>,
                children: <div className={s.detail}>
                    {v?.children?.map((item, ind) => (
                        <div className={s.dItem} key={ind}>
                            <div className={s.label} style={{width:`${maxLen * 7.5}px`, padding:'2px'}}>{item?.key}</div>
                            <div className={s.val} dangerouslySetInnerHTML={{ __html: item?.val }}></div>
                        </div>
                    ))}
                </div>

            })
         }
        )
    }
    useEffect(() => {
        setItems(getItems())
    }, [])
    return (
        <div className={`${s.wrap}`}>
            {/* <div className={`${s.item} ${activeItem === 0 ? s.active : ''}`}>
                <div className={s.title}>
                    <div className={s.l}>Body</div>
                    <div className={s.r} onClick={()=>{setActiveItem(0)}}>+</div>
                </div>
                <div className={s.detail}>
                    <div className={s.dItem}>
                        <div className={s.label}>HEIGHT</div>
                        <div className={s.val}>164.0mm</div>
                    </div>
                    <div className={s.dItem}>
                        <div className={s.label}>HEIGHT</div>
                        <div className={s.val}>164.0mm</div>
                    </div>
                    <div className={s.dItem}>
                        <div className={s.label}>HEIGHT</div>
                        <div className={s.val}>164.0mm</div>
                    </div>

                </div>
            </div>
            <div className={`${s.item} ${activeItem === 1 ? s.active : ''}`}>
                <div className={s.title}>
                    <div className={s.l}>XXXX</div>
                    <div className={s.r} onClick={()=>{setActiveItem(1)}}>+</div>
                </div>
                <div className={s.detail}>
                    <div className={s.dItem}>
                        <div className={s.label}>HEIGHT</div>
                        <div className={s.val}>164.0mm</div>
                    </div>
                    <div className={s.dItem}>
                        <div className={s.label}>HEIGHT</div>
                        <div className={s.val}>164.0mm</div>
                    </div>
                </div>
            </div> */}
            <Collapse
                className='collapV'
                expandIcon={({ isActive }) => {
                    return <CustomIcon width={20} height={20} imgType={'png'} imgName={isActive ? 'UI_Button_Arrow_Up_01' : 'UI_Button_Arrow_Down_01'} />
                }}
                expandIconPosition="end"
                accordion
                items={items}>
            </Collapse>
        </div>
    );

}

export default memo(AttrVert);

import React, { useRef, useEffect, memo, useState, useMemo} from "react";
import s from "./index.module.less";
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import AttrVert from "../AttrVert";
import { ScrollToPlugin } from "gsap/all";
import CustomIcon from '../../components/CustomIcon';

//import { ScrollSmoother } from "gsap-trial/all";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
//gsap.registerPlugin(ScrollSmoother);

const AlphaPhoneVerticalTimeline = ({

})=>{
    const tlTriggerRef = useRef(null);
    const rootRef = useRef(null);
    const tl1Ref = useRef(null);

    const longTextColorGrayLight = `rgba(100,100,100,1)`;
    const longTextColorLight = `rgba(255,255,255,1)`;
    const longTextHighLightColor = `rgba(0,255,0,1)`;

    const video_section3_ref = useRef(null);
    const video_section4_ref = useRef(null);

    //phone part
    const phoneExhibitCanvasRef = useRef(null);
    const chipCanvasRef = useRef(null);
	
	let phoneExhibitCanvas = null;
	let phoneExhibitContext = null;

	let chipCanvas = null;
	let chipContext = null;

    //openinganimation
    let openingAnimationLastFrame = -1;
    const openingAnimationFrameCount = 23;
    const openingAnimationCurrentFrame = (index) => {
        const tempImg = (index + 1).toString().padStart(4, '0');
	    return `./img/phoneExhibit_vertical/${tempImg}.webp`;
    }
    const openingAnimationImages = [];
    const openingAnimationFrames = {
        frame: 0
    };
    for (let i = 0; i < openingAnimationFrameCount; i++) {
		const img = new Image();
		img.src = openingAnimationCurrentFrame(i);
		openingAnimationImages.push(img);
	}
    openingAnimationImages[0].onload = openingAnimationRender;

    function openingAnimationRender() {
		if (openingAnimationFrames.frame != openingAnimationLastFrame){
            //console.log('phoneExhibitPart1Render', openingAnimationFrames?.frame);
			if (phoneExhibitContext){
				phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                phoneExhibitContext.drawImage(openingAnimationImages[openingAnimationFrames.frame], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
			}
			openingAnimationLastFrame = openingAnimationFrames.frame;
		}
	}

    //title phone animation
    /*
    let titlePhoneAnimationLastFrame = -1;
    const titlePhoneAnimationFrameCount = 13;
    const titlePhoneAnimationCurrentFrame = (index) => {
        const tempImg = (index + openingAnimationFrameCount).toString().padStart(4, '0');
	    return `./img/phoneExhibit_vertical/${tempImg}.webp`;
    }
    const titlePhoneAnimationImages = [];
    const titlePhoneAnimationFrames = {
        frame: 0
    };
    for (let i = 0; i < titlePhoneAnimationFrameCount; i++) {
		const img = new Image();
		img.src = titlePhoneAnimationCurrentFrame(i);
		titlePhoneAnimationImages.push(img);
	}
    function titlePhoneAnimationRender() {
		if (titlePhoneAnimationFrames.frame != titlePhoneAnimationLastFrame){
            console.log('titlePhoneAnimationRender', titlePhoneAnimationFrames?.frame);
			if (phoneExhibitContext){
				phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                phoneExhibitContext.drawImage(titlePhoneAnimationImages[titlePhoneAnimationFrames.frame], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
			}
			titlePhoneAnimationLastFrame = titlePhoneAnimationFrames.frame;
		}
	}*/

    //seq2(toPage2 + page2)
    let seq2AnimationLastFrame = -1;
    const seq2AnimationFrameCount = 48;
    const seq2AnimationCurrentFrame = (index) => {
        const tempImg = (index + openingAnimationFrameCount).toString().padStart(4, '0');
	    return `./img/phoneExhibit_vertical/${tempImg}.webp`;
    }
    const seq2AnimationImages = [];
    const seq2AnimationFrames = {
        frame: 0
    };
    for (let i = 0; i < seq2AnimationFrameCount; i++) {
		const img = new Image();
		img.src = seq2AnimationCurrentFrame(i);
		seq2AnimationImages.push(img);
	}
    function seq2AnimationRender() {
		if (seq2AnimationFrames.frame != seq2AnimationLastFrame){
            //console.log('seq2AnimationRender', seq2AnimationFrames?.frame);
			if (phoneExhibitContext){
				phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                phoneExhibitContext.drawImage(seq2AnimationImages[seq2AnimationFrames.frame], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
			}
			seq2AnimationLastFrame = seq2AnimationFrames.frame;
		}
	}

    //seq3(to page3)
    let toPage3AnimationLastFrame = -1;
    const toPage3AnimationFrameCount = 51;
    const toPage3AnimationCurrentFrame = (index) => {
        const tempImg = (index + openingAnimationFrameCount + seq2AnimationFrameCount - 1).toString().padStart(4, '0');
	    return `./img/phoneExhibit_vertical/${tempImg}.webp`;
    }
    const toPage3AnimationImages = [];
    const toPage3AnimationFrames = {
        frame: 0
    };
    for (let i = 0; i < toPage3AnimationFrameCount; i++) {
		const img = new Image();
		img.src = toPage3AnimationCurrentFrame(i);
		toPage3AnimationImages.push(img);
	}
    function toPage3AnimationRender() {
		if (toPage3AnimationFrames.frame != toPage3AnimationLastFrame){
            //console.log('toPage3AnimationRender', toPage3AnimationFrames?.frame);
			if (phoneExhibitContext){
				phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                phoneExhibitContext.drawImage(toPage3AnimationImages[toPage3AnimationFrames.frame], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
			}
			toPage3AnimationLastFrame = toPage3AnimationFrames.frame;
		}
	}

    //chip
    let chipAnimationLastFrame = -1;
    const chipAnimationFrameCount = 32;
    const chipAnimationCurrentFrame = (index) => {
        const tempImg = (index + 0).toString()/*.padStart(4, '0')*/;
	    return `./img/phoneChip_vertical/xinpian${tempImg}.webp`;
    }
    const chipAnimationImages = [];
    const chipAnimationFrames = {
        frame: 0
    };
    for (let i = 0; i < chipAnimationFrameCount; i++) {
        const img = new Image();
        img.src = chipAnimationCurrentFrame(i);
        chipAnimationImages.push(img);
    }
    function chipAnimationRender() {
		if (chipAnimationFrames.frame != chipAnimationLastFrame){
            //console.log('chipAnimationRender', chipAnimationImages, chipAnimationFrames?.frame);
			if (chipContext){
				chipContext.clearRect(0, 0, chipCanvas.width, chipCanvas.height);
                chipContext.drawImage(chipAnimationImages[chipAnimationFrames.frame], 0, 0, chipCanvas.width, chipCanvas.height);
			}
			chipAnimationLastFrame = chipAnimationFrames.frame;
		}
	}

    //seq4(page3后半部分 + phoneExhibit结束部分)
    let seq4AnimationLastFrame = -1;
    const seq4AnimationFrameCount = 131;
    const seq4AnimationCurrentFrame = (index) => {
        const tempImg = (index + openingAnimationFrameCount + seq2AnimationFrameCount + toPage3AnimationFrameCount - 2).toString().padStart(4, '0');
	    return `./img/phoneExhibit_vertical/${tempImg}.webp`;
    }
    const seq4AnimationImages = [];
    const seq4AnimationFrames = {
        frame: 0
    };
    for (let i = 0; i < seq4AnimationFrameCount; i++) {
        const img = new Image();
        img.src = seq4AnimationCurrentFrame(i);
        seq4AnimationImages.push(img);
    }
    function seq4AnimationRender() {
		if (seq4AnimationFrames.frame != seq4AnimationLastFrame){
            //console.log('seq4AnimationRender', seq4AnimationFrames?.frame);
			if (phoneExhibitContext){
				phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                phoneExhibitContext.drawImage(seq4AnimationImages[seq4AnimationFrames.frame], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
			}
			seq4AnimationLastFrame = seq4AnimationFrames.frame;
		}
	}

    //fontsize
    const textMinSize = 320;
    const textMaxSize = 932;

    const alphaMobileMinValue = 50;
    const alphaMobileMaxValue = 120;

    const alphaMobileDesMinValue = 20;
    const alphaMobileDesMaxValue = 32;

    const titleTextMinValue = 28;
    const titleTextMaxValue = 60;

    const bodyTextMinValue = 22;
    const bodyTextMaxValue = 48;

    const getAlphaMobileFontSize = () =>{
        return `calc(${alphaMobileMinValue}px + ${alphaMobileMaxValue - alphaMobileMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getAlphaMobileDesFontSize = () =>{
        return `calc(${alphaMobileDesMinValue}px + ${alphaMobileDesMaxValue - alphaMobileDesMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getTitleTextFontSize = () =>{
        return `calc(${titleTextMinValue}px + ${titleTextMaxValue - titleTextMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getBodyTextFontSize = () =>{
        return `calc(${bodyTextMinValue}px + ${bodyTextMaxValue - bodyTextMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const page3EndTextTitleMinValue = 30;
    const page3EndTextTitleMaxValue = 55;

    const getPage3EndTextTitleFontSize = () =>{
        return `calc(${page3EndTextTitleMinValue}px + ${page3EndTextTitleMaxValue - page3EndTextTitleMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const page3EndTextDesTitleMinValue = 24;
    const page3EndTextDesTitleMaxValue = 50;

    const getPage3EndTextDesTitleFontSize = () =>{
        return `calc(${page3EndTextDesTitleMinValue}px + ${page3EndTextDesTitleMaxValue - page3EndTextDesTitleMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const page3EndTextDesMinValue = 10;
    const page3EndTextDesMaxValue = 20;

    const getPage3EndTextDesFontSize = () =>{
        return `calc(${page3EndTextDesMinValue}px + ${page3EndTextDesMaxValue - page3EndTextDesMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    //airdrop
    const airDropGiftRef = useRef(null);
    const airDropPhone2Ref = useRef(null);

    let airDropGiftCanvas = null;
	let airDropGiftContext = null;
	let airDropGiftLastFrame = -1;

	const airDropGiftFrameCount = 140;
	const airDropGiftCurrentFrame = (index) => {
        const tempImg = (index + 1).toString().padStart(4, '0');
	    return `./img/airDropGift_vertical/${tempImg}.webp`;
    }
	
	const airDropGiftImages = []
	const airDropGiftFrames = {
		frame: 0
	};
	
	for (let i = 0; i < airDropGiftFrameCount; i++) {
		const img = new Image();
		img.src = airDropGiftCurrentFrame(i);
		airDropGiftImages.push(img);
	}
	
	airDropGiftImages[0].onload = airDropGiftRender;
	
	function airDropGiftRender() {
		if (airDropGiftFrames.frame != airDropGiftLastFrame){
			if (airDropGiftContext){
				airDropGiftContext.clearRect(0, 0, airDropGiftCanvas.width, airDropGiftCanvas.height);
				airDropGiftContext.drawImage(airDropGiftImages[airDropGiftFrames.frame], 0, 0); 
			}
			//console.log('airDropGiftRender', airDropGift);
			airDropGiftLastFrame = airDropGiftFrames.frame;
		}
	}

    const airDropTextRootRef = useRef(null);

    let airDropPhone2Canvas = null;
	let airDropPhone2Context = null;
	let airDropPhone2LastFrame = -1;

	const airDropPhone2FrameCount = 95;
	const airDropPhone2CurrentFrame = (index) => {
        const tempImg = (index + 56).toString().padStart(4, '0');
	    return `./img/airDropPhone_vertical/${tempImg}.webp`;
    }
	
	const airDropPhone2Images = []
	const airDropPhone2 = {
		frame: 0
	};
	
	for (let i = 0; i < airDropPhone2FrameCount; i++) {
		const img = new Image();
		img.src = airDropPhone2CurrentFrame(i);
		airDropPhone2Images.push(img);
	}
	
	airDropPhone2Images[0].onload = airDropPhone2Render;
	
	function airDropPhone2Render() {
		if (airDropPhone2.frame != airDropPhone2LastFrame){
			if (airDropPhone2Context){
				airDropPhone2Context.clearRect(0, 0, airDropPhone2Canvas.width, airDropPhone2Canvas.height);
				airDropPhone2Context.drawImage(airDropPhone2Images[airDropPhone2.frame], 0, 0, airDropPhone2Canvas.width, airDropPhone2Canvas.height); 
			}
			//console.log('airDropPhone2Render', airDropPhone2);
			airDropPhone2LastFrame = airDropPhone2.frame;
		}
	}

    useEffect(()=>{
        const vp3 = video_section3_ref.current;
        if (vp3 && vp3.webkitSetPresentationMode) vp3.webkitSetPresentationMode("inline");

        const vp4 = video_section4_ref.current;
        if (vp4 && vp4.webkitSetPresentationMode) vp4.webkitSetPresentationMode("inline");
    
    }, []);

    const phoneSeqCanvasWidth = 1125;
    const phoneSeqCanvasHeight = 2436;

    const {contextSafe} = useGSAP(
        () => {
            if (!phoneExhibitCanvasRef.current) return;
            if (!phoneExhibitCanvas){
                phoneExhibitCanvas = phoneExhibitCanvasRef.current;
                phoneExhibitContext = phoneExhibitCanvas.getContext("2d");
                phoneExhibitCanvas.width = phoneSeqCanvasWidth;
                phoneExhibitCanvas.height = phoneSeqCanvasHeight;
            }
            if (!chipCanvas){
                chipCanvas = chipCanvasRef.current;
                chipContext = chipCanvas.getContext("2d");
                chipCanvas.width = 946;
                chipCanvas.height = 2048;
            }
            if (!airDropGiftCanvas){
                airDropGiftCanvas = airDropGiftRef.current;
                airDropGiftContext = airDropGiftCanvas.getContext("2d");
                airDropGiftCanvas.width = phoneSeqCanvasWidth;
                airDropGiftCanvas.height = phoneSeqCanvasHeight;
            }
            if (!airDropPhone2Canvas){
                airDropPhone2Canvas = airDropPhone2Ref.current;
                airDropPhone2Context = airDropPhone2Canvas.getContext("2d");
                airDropPhone2Canvas.width = phoneSeqCanvasWidth;
                airDropPhone2Canvas.height = phoneSeqCanvasHeight;
            }

            /*
            let smoother = ScrollSmoother.create({
                smooth: 1,
                effects: true,
                normalizeScroll: false,
                speed:1,
                content:'.tlTrigger',
              });*/
            //smoother.effects('.tlTrigger',{speed: 1, lag: 0.1});
            
            //const maxScrollY = ScrollTrigger.maxScroll(window, false);
            //const pos = tl.scrollTrigger.labelToScroll("buyPhone");

            let firstAnimLoaded = false;
            let waitFirstAnimLoadTween = gsap.to({}, {
                duration: 0.1,
                repeat: -1,
                onUpdate: () =>{
                    if (!firstAnimLoaded)
                        onClickGoTop();
                    else
                        waitFirstAnimLoadTween.kill();
                }
            });

            let trigger = {
                trigger: ".tlTrigger",
                start: "top top",
                end: "bottom bottom",
                //markers: true,
                scrub: 0.1,
                //snap: "labelsDirectional",
            };
            
            let tl1 = gsap.timeline({
                scrollTrigger: trigger, defaults: { ease: "power1.inout" }
            });

            tl1Ref.current = tl1;

            //label
            const page1StartFrame = 0;
            const page1LastFrame = 4;
            const toPage2StartFrame = page1StartFrame + page1LastFrame;
            const toPage2LastFrame = 9;
            const page2StartFrame = toPage2StartFrame + toPage2LastFrame;
            const page2LastFrame = 14;
            const toPage3StartFrame = page2StartFrame + page2LastFrame;
            const toPage3LastFrame = 6;
            const page3StartFrame = toPage3StartFrame + toPage3LastFrame;
            const page3LastFrame = 14;
            const page4StartFrame = page3StartFrame + page3LastFrame;
            const page4LastFrame = 16;
            const airDropStartFrame = page4StartFrame + page4LastFrame;

            tl1.addLabel("openingAnimation", page1StartFrame);
            tl1.addLabel("toPage2", toPage2StartFrame);
            tl1.addLabel("page2", page2StartFrame);
            tl1.addLabel("toPage3", toPage3StartFrame);
            tl1.addLabel("page3", page3StartFrame);
            tl1.addLabel("page4", page4StartFrame);
            tl1.addLabel("airDrop", airDropStartFrame);

            //tween
            //phone exhibition变暗50%
            let darkenPhoneExhibition = gsap.to(".phoneExhibitionAnimation", {
                duration: 0.8,
                opacity: 0.5,
            })
            darkenPhoneExhibition.pause();

            //phone exhibition变暗75%
            let darken75PhoneExhibition = gsap.to(".phoneExhibitionAnimation", {
                duration: 0.8,
                opacity: 0.25,
            })
            darken75PhoneExhibition.pause();

            //手机参数列表显示
            let phoneDesTween = gsap.to(".attr", { 
                duration: 0.8, 
                autoAlpha: 1, 
                //bottom: '50%'
            });
            phoneDesTween.pause();

            //首页文本显示
            let buyPhoneTextInTween = gsap.to(".buyPhoneText", {
                duration: 0.8,
                autoAlpha: 1,
                top: '50%',
                onUpdate: () =>{
                    onClickGoTop();
                }
            });
            buyPhoneTextInTween.pause();

            //首页文本消失
            let buyPhoneTextOutTween = gsap.to(".buyPhoneText", 
            {
                duration: 0.8,
                autoAlpha: 0,
                //top: '10%'
            });
            buyPhoneTextOutTween.pause();

            //page2文本显示
            let phoneText1InTween = gsap.to(".phoneText1", {
                duration: 0.5,
                autoAlpha: 1,
                top: '100%'
            });
            phoneText1InTween.pause();

            //page2文本消失
            let phoneText1OutTween = gsap.to(".phoneText1", 
            {
                duration: 0.5,
                autoAlpha: 0,
                top: '-40%'
            });
            phoneText1OutTween.pause();

            //page2第一段文本高亮动画
            let seq2Text1ColorLightTween = gsap.to(".seq2Text1", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq2Text1ColorLightTween.pause();

            //page2第二段文本高亮动画
            let seq2Text2ColorLightTween = gsap.to(".seq2Text2", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq2Text2ColorLightTween.pause();

            //page2第三段文本高亮动画
            let seq2Text3ColorLightTween = gsap.to(".seq2Text3", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq2Text3ColorLightTween.pause();

            //page2第四段文本高亮动画
            let seq2Text4ColorLightTween = gsap.to(".seq2Text4", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq2Text4ColorLightTween.pause();

            //page2第五段文本高亮动画
            let seq2Text5ColorLightTween = gsap.to(".seq2Text5", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq2Text5ColorLightTween.pause();

            //page3文本显示
            let phoneText2InTween = gsap.to(".phoneText2", {
                duration: 0.5,
                autoAlpha: 1,
                top: '120%'
            });
            phoneText2InTween.pause();

            //page3文本消失
            let phoneText2OutTween = gsap.to(".phoneText2", 
            {
                duration: 0.5,
                autoAlpha: 0,
                top: '-40%'
            });
            phoneText2OutTween.pause();

            //page3第一段文本高亮动画
            let seq3Text1ColorLightTween = gsap.to(".seq3Text1", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq3Text1ColorLightTween.pause();

            //page3第二段文本高亮动画
            let seq3Text2ColorLightTween = gsap.to(".seq3Text2", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq3Text2ColorLightTween.pause();

            //page3第三段文本高亮动画
            let seq3Text3ColorLightTween = gsap.to(".seq3Text3", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq3Text3ColorLightTween.pause();

            //page2第四段文本高亮动画
            let seq3Text4ColorLightTween = gsap.to(".seq3Text4", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq3Text4ColorLightTween.pause();

            //page2第五段文本高亮动画
            let seq3Text5ColorLightTween = gsap.to(".seq3Text5", {
                duration: 0.5,
                color: longTextColorLight,
            });
            seq3Text5ColorLightTween.pause();

            //芯片显示
            let phoneChipInTween = gsap.to(".chipAnimation",
            {
                duration: 0.5,
                autoAlpha: 1,
            });
            phoneChipInTween.play();

            //手机屏幕内文本显示 
            let page3EndTextInTween = gsap.to(".page3EndTextRoot", {
                duration: 0.2,
                autoAlpha: 1,
            });
            page3EndTextInTween.pause();

            //手机展示canvas消失
            let phoneExhibitionOutTween = gsap.to(".phoneExhibitionAnimationRoot", {
                duration: 0.2,
                autoAlpha: 0,
            });
            phoneExhibitionOutTween.pause();

            //空投文本显示
            let airDropTextInTween = gsap.to(".airDropText", {
                duration: 0.5,
                autoAlpha: 1,
                top: '110%'
            });
            airDropTextInTween.pause();

            //空投文本消失
            let airDropTextOutTween = gsap.to(".airDropText", 
            {
                duration: 0.5,
                autoAlpha: 0,
                top: '-40%'
            });
            airDropTextOutTween.pause();

            //空投第一段文本高亮动画
            let airDropText1ColorLightTween = gsap.to(".airDropText1", {
                duration: 0.5,
                color: longTextColorLight,
            });
            airDropText1ColorLightTween.pause();

            //空投第二段文本高亮动画
            let airDropText2ColorLightTween = gsap.to(".airDropText2", {
                duration: 0.5,
                color: longTextColorLight,
            });
            airDropText2ColorLightTween.pause();

            //空投第三段文本高亮动画
            let airDropText3ColorLightTween = gsap.to(".airDropText3", {
                duration: 0.5,
                color: longTextColorLight,
            });
            airDropText3ColorLightTween.pause();

            //空投第四段文本高亮动画
            let airDropText4ColorLightTween = gsap.to(".airDropText4", {
                duration: 0.5,
                color: longTextColorLight,
            });
            airDropText4ColorLightTween.pause();

            //空投第五段文本高亮动画
            let airDropText5ColorLightTween = gsap.to(".airDropText5", {
                duration: 0.5,
                color: longTextColorLight,
            });
            airDropText5ColorLightTween.pause();

            //空投礼物消失动画
            let airDropGiftInTween = gsap.to(".airDropGift", {
                duration: 0.2,
                autoAlpha: 1, 
            });
            airDropGiftInTween.pause();

            //空投手机第二部分1个分裂3个手机动画
            let airDropPhoneChangeTween = gsap.to(airDropPhone2, {
                frame: airDropPhone2FrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 1.5,
                onUpdate: airDropPhone2Render
            });
            airDropPhoneChangeTween.pause();

            //seq2 animation
            const seq2AnimationTween = gsap.to(seq2AnimationFrames, {
                frame: seq2AnimationFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 1.5,
                onUpdate: seq2AnimationRender,
            });
            seq2AnimationTween.pause();

            //seq3 animation
            const seq3AnimationTween = gsap.to(toPage3AnimationFrames, {
                frame: toPage3AnimationFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 1.5,
                onUpdate: toPage3AnimationRender,
                onComplete: () =>{
                    phoneChipInTween.play();
                    chipAnimationTween.play();
                }
            });
            seq3AnimationTween.pause();

            //seq4 animation
            const seq4AnimationTween = gsap.to(seq4AnimationFrames, {
                frame: seq4AnimationFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 2.5,
                onUpdate: seq4AnimationRender,
                onReverseComplete: () =>{
                    phoneChipInTween.play();
                    chipAnimationTween.play();
                },
                onComplete: () =>{
                    page3EndTextInTween.play();
                }
            });
            seq4AnimationTween.pause();

            //chip animation
            const chipAnimationTween = gsap.to(chipAnimationFrames, { 
                frame: chipAnimationFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 3,
                repeat: -1,
                onUpdate: () =>{
                    chipAnimationRender()
                },
            });
            chipAnimationTween.pause();

            //airDrop gift animation
            const airDropGiftAnimationTween = gsap.to(airDropGiftFrames, {
                frame: airDropGiftFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 4,
                onUpdate: airDropGiftRender,
                onComplete: () =>{
                    airDropGiftInTween.reverse();
                }
            });
            airDropGiftAnimationTween.pause();

            //opening animation
            const openingAnimationTween = gsap.to(openingAnimationFrames, { 
                frame: openingAnimationFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 1,
                onUpdate: () =>{
                    onClickGoTop();
                    openingAnimationRender();
                },
                onComplete: () =>{
                    buyPhoneTextInTween.restart();
                    darkenPhoneExhibition.restart();
                },
                onStart: () =>{
                    seq2AnimationTween.seek(0);
                    seq2AnimationTween.pause();
                    if (phoneExhibitContext){
                        phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                        phoneExhibitContext.drawImage(seq2AnimationImages[0], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                    }
                }
            });
            openingAnimationTween.pause();

            //最后一帧加载完毕后开始播放开局动画
            openingAnimationImages[openingAnimationFrameCount - 1].onload = ()=>{
                console.log('openingAnimationTween', openingAnimationFrameCount, 'frames loaded');
                openingAnimationRender();
                firstAnimLoaded = true;
                if (openingAnimationTween) openingAnimationTween.play();
            };

            //openingAnimation
            /*
            tl1.to(titlePhoneAnimationFrames, {
                frame: titlePhoneAnimationFrameCount - 1,
                snap: "frame",
                ease: "none",
                duration: 4,
                //onUpdate: titlePhoneAnimationRender,
            }, ">");
            */

            tl1.to('.buyPhoneText', { 
                duration: 1,
                onComplete: () =>{
                    //顺滑过快显示动画归位
                    buyPhoneTextInTween.seek(0.8);
                    buyPhoneTextInTween.pause();
                    //首页元素消失动画
                    buyPhoneTextOutTween.play();
                    darkenPhoneExhibition.reverse();
                },
                onReverseComplete: () =>{
                    //逆滑过快消失动画归位
                    buyPhoneTextOutTween.seek(0);
                    buyPhoneTextOutTween.pause();
                    darkenPhoneExhibition.seek(0.8);
                    darkenPhoneExhibition.pause();
                    //seq2AnimationTween.seek(0);
                    //seq2AnimationTween.pause();
                }
            }, ">+=2");
            //购买文本消失动画
            tl1.to('.buyPhoneText', { 
                duration: 1,
                onReverseComplete: () =>{
                    //首页元素消失动画倒放
                    buyPhoneTextOutTween.reverse();
                    darkenPhoneExhibition.play();
                },
                onComplete: () =>{
                    //顺滑过快消失动画归位
                    buyPhoneTextOutTween.seek(0.8);
                    buyPhoneTextOutTween.pause();
                    darkenPhoneExhibition.seek(0);
                    darkenPhoneExhibition.pause();
                },
            }, ">");

            //到page2的转场动画
            tl1.to('.phoneText1', {
                duration: 1, 
                onReverseComplete: () =>{
                    seq2AnimationTween.reverse();
                },
                onStart: () =>{
                    seq2AnimationTween.play();
                }
            }, "toPage2");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () =>{
                    phoneText1InTween.seek(0);
                    phoneText1InTween.pause();
                    darken75PhoneExhibition.seek(0);
                    darken75PhoneExhibition.pause();
                },
                onStart: () =>{
                    darken75PhoneExhibition.play();
                    phoneText1InTween.restart();
                    seq2Text1ColorLightTween.play();
                }
            }, "toPage2+=5");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () =>{
                    //逆滑过快seq3动画归位
                    seq3AnimationTween.seek(0);
                    seq3AnimationTween.pause();
                    if (phoneExhibitContext){
                        phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                        phoneExhibitContext.drawImage(toPage3AnimationImages[0], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                    }
                },
                onComplete: () =>{
                    //顺滑过快page2文本显示动画归位
                    phoneText1InTween.seek(0.5);
                    phoneText1InTween.pause();
                    darken75PhoneExhibition.seek(0.8);
                    darken75PhoneExhibition.pause();
                }
            }, ">");

            //文字1 滑动**
            tl1.to('.phoneText1', {
                duration: 16,
                top: `0%`,
            }, ">");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () => {
                    seq2Text1ColorLightTween.play();
                    seq2Text2ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq2Text1ColorLightTween.reverse();
                    seq2Text2ColorLightTween.play();
                }
            }, "<+=1");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () => {
                    seq2Text2ColorLightTween.play();
                    seq2Text3ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq2Text2ColorLightTween.reverse();
                    seq2Text3ColorLightTween.play();
                }
            }, ">+=0");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () => {
                    seq2Text3ColorLightTween.play();
                    seq2Text4ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq2Text3ColorLightTween.reverse();
                    seq2Text4ColorLightTween.play();
                }
            }, ">+=0");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () => {
                    seq2Text4ColorLightTween.play();
                    seq2Text5ColorLightTween.reverse();
                    darken75PhoneExhibition.seek(0.8);
                    darken75PhoneExhibition.pause();
                },
                onComplete: () =>{
                    seq2Text4ColorLightTween.reverse();
                    seq2Text5ColorLightTween.play();
                }
            }, ">+=0");

            tl1.to('.phoneText1', {
                duration: 1,
                onReverseComplete: () => {
                    phoneText1OutTween.seek(0);
                    phoneText1OutTween.pause();
                    //逆滑过快第三段文本强制归位
                    phoneText2InTween.seek(0);
                    phoneText2InTween.pause();
                    darken75PhoneExhibition.play();
                },
                onComplete: () =>{
                    phoneText1OutTween.play();
                    darken75PhoneExhibition.reverse();
                }
            }, ">+=10");

            //到page3的转场动画
            tl1.to('.phoneText2', {
                duration: 1,
                onComplete: () =>{
                    seq2AnimationTween.seek(1);
                    seq2AnimationTween.pause();

                    if (phoneExhibitContext){
                        phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                        phoneExhibitContext.drawImage(seq2AnimationImages[seq2AnimationImages.length - 1], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                    }
                    phoneText1OutTween.seek(0.5);
                    phoneText1OutTween.pause();
                    darken75PhoneExhibition.seek(0);
                    darken75PhoneExhibition.pause();
                }
            }, "toPage3");

            tl1.to('.phoneText2', {
                duration: 1, 
                onReverseComplete: () =>{
                    seq3AnimationTween.reverse();
                    phoneChipInTween.seek(0);
                    phoneChipInTween.pause();
                    chipAnimationTween.pause();
                },
                onStart: () =>{
                    seq3AnimationTween.play();
                }
            }, "toPage3+=1");

            //page3
            //page3背景视频出现
            /*tl1.to('.video_section3', {
                duration: 5, 
                autoAlpha:0.7, 
                ease:"slow",
                onReverseComplete: async() =>{
                    const vp = video_section3_ref.current;
                    if (vp) {await vp.pause();}
                }, 
                onStart: async()=>{
                    const vp = video_section3_ref.current;
                    if (vp && vp.paused){vp.currentTime = 0;try{await vp.play();}catch(e){console.error(e)}}
                }
            }, "toPage3-=8");*/

            tl1.to('.phoneText2', {
                duration: 1,
                onReverseComplete: () => {
                    phoneText2OutTween.pause();
                    
                    phoneText2InTween.seek(0.01);
                    phoneText2InTween.reverse();
                },
                onComplete: () =>{
                    phoneText2OutTween.pause();

                    phoneText2InTween.seek(0.99);
                    phoneText2InTween.play();

                    seq3Text1ColorLightTween.play();
                }
            }, "toPage3+=2");

            tl1.to('.phoneText2', {
                duration: 1,
                onComplete: () =>{
                    phoneText2InTween.seek(0.99);
                    phoneText2InTween.pause();
                }
            }, ">");

            //文字描述2 拖动**
            tl1.to('.phoneText2', {
                duration: 16,
                top: `-20%`,
            }, ">");


            tl1.to('.phoneText2', {
                duration: 1,
                onReverseComplete: () => {
                    seq4AnimationTween.seek(0);
                    seq4AnimationTween.pause();
                    if (phoneExhibitContext){
                        phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                        phoneExhibitContext.drawImage(seq4AnimationImages[0], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                    }
                    seq3Text1ColorLightTween.play();
                    seq3Text2ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq3Text1ColorLightTween.reverse();
                    seq3Text2ColorLightTween.play();
                }
            }, "<+=1");

            tl1.to('.phoneText2', {
                duration: 1,
                onReverseComplete: () => {
                    seq3Text2ColorLightTween.play();
                    seq3Text3ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq3Text2ColorLightTween.reverse();
                    seq3Text3ColorLightTween.play();
                }
            }, ">+=0");

            tl1.to('.phoneText2', {
                duration: 1,
                onReverseComplete: () => {
                    seq3Text3ColorLightTween.play();
                    seq3Text4ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq3Text3ColorLightTween.reverse();
                    seq3Text4ColorLightTween.play();
                }
            }, ">+=0");

            tl1.to('.phoneText2', {
                duration: 1,
                onReverseComplete: () => {
                    seq3Text4ColorLightTween.play();
                    seq3Text5ColorLightTween.reverse();
                },
                onComplete: () =>{
                    seq3Text4ColorLightTween.reverse();
                    seq3Text5ColorLightTween.play();
                }
            }, ">+=0");

            tl1.to('.phoneText2', {
                duration: 1,
                onReverseComplete: () => {
                    phoneText2OutTween.seek(0);
                    phoneText2OutTween.pause();
                },
                onComplete: () =>{
                    phoneText2OutTween.play();
                }
            }, ">+=11");
            
            /*tl1.to('.video_section3', {
                duration: 1, 
                autoAlpha:0, 
                onUpdate: async()=>{
                    const vp = video_section3_ref.current;
                    if (vp && vp.paused){vp.currentTime = 0;try{await vp.play();}catch(e){console.error(e)}}
                },
                onComplete: async() =>{
                    const vp = video_section3_ref.current;
                    if (vp) {await vp.pause();}
                }
            }, "<");*/

            //page4
            tl1.to('.phoneText2', {
                duration: 1,
                onComplete: () =>{
                    //顺滑过快第三段动画强制播完
                    seq3AnimationTween.seek(1.5);
                    seq3AnimationTween.pause();
                    if (phoneExhibitContext){
                        phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                        phoneExhibitContext.drawImage(toPage3AnimationImages[toPage3AnimationImages.length - 1], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                    }
                    phoneChipInTween.seek(0);
                    phoneChipInTween.pause();
                    chipAnimationTween.pause();
                    //顺滑过快第三段文本强制消失
                    phoneText2OutTween.seek(0.5);
                    phoneText2OutTween.pause();
                }
            }, "page4");

            tl1.to('.phoneText2', {
                duration: 1, 
                onReverseComplete: () =>{
                    seq4AnimationTween.reverse();
                    //逆滑手机屏幕文本消失
                    page3EndTextInTween.seek(0);
                    page3EndTextInTween.pause();
                },
                onStart: () =>{
                    seq4AnimationTween.play();
                }
            }, "page4+=1");

            //airDrop
            tl1.to('.phoneExhibitionAnimation', {
                duration: 20,
                top: '-50%',
                onComplete: () =>{
                    seq4AnimationTween.seek(2.5);
                    seq4AnimationTween.pause();
                    if (phoneExhibitContext){
                        phoneExhibitContext.clearRect(0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                        phoneExhibitContext.drawImage(seq4AnimationImages[seq4AnimationImages.length - 1], 0, 0, phoneExhibitCanvas.width, phoneExhibitCanvas.height);
                    }
                }
            }, "airDrop");

            tl1.to('.phoneExhibitionAnimation', {
                duration: 1,
                onReverseComplete: ()=>{
                    phoneExhibitionOutTween.reverse();
                },
                onComplete: () =>{
                    phoneExhibitionOutTween.play();
                }
            }, ">");

            tl1.to('.page3EndTextRoot', {
                duration: 20,
                top: '-50%',
                onReverseComplete: () =>{
                    page3EndTextInTween.seek(0.2);
                    page3EndTextInTween.pause();
                },
                onComplete: () =>{
                    page3EndTextInTween.seek(0);
                    page3EndTextInTween.pause();
                }
            }, "airDrop");

            tl1.to('.page3EndTextRoot', {
                duration: 1,
                onReverseComplete: ()=>{
                    page3EndTextInTween.play();
                },
            }, ">");
            tl1.to('.video_section4', {
                duration: 2, 
                autoAlpha: 1, 
                onReverseComplete: async() =>{
                    const vp = video_section4_ref.current;
                    if (vp) {await vp.pause();}
                }, 
                onStart: async()=>{
                    const vp = video_section4_ref.current;
                    if (vp && vp.paused){vp.currentTime = 0;try{await vp.play();}catch(e){console.error(e)}}
                }
            }, "airDrop+=20");
            //空投手机显示
            tl1.to('.airDropPhone2', {
                duration: 0.5,
                autoAlpha: 1,
            }, "airDrop+=1");
            tl1.to('.airDropPhone2', {
                duration: 20,
                top: '50%',
            }, "airDrop+=1");
            //空投礼物动画
            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () =>{
                    airDropGiftAnimationTween.seek(0);
                    airDropGiftAnimationTween.pause();
                    airDropGiftInTween.seek(0);
                    airDropGiftInTween.pause();
                },
                onComplete: () =>{
                    airDropGiftInTween.play();
                    airDropGiftAnimationTween.restart();
                }
            }, "airDrop+=10");

            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () =>{
                    //逆滑过快文本显示动画归位
                    airDropTextInTween.seek(0);
                    airDropTextInTween.pause();
                },
            }, "airDrop+=18");
            //空投文本显示动画
            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () => {
                    airDropTextOutTween.pause();
                    airDropTextInTween.reverse();
                },
                onComplete: () =>{
                    airDropTextOutTween.pause();
                    airDropTextInTween.play();
                    airDropText1ColorLightTween.play();
                }
            }, "airDrop+=22");
            //*** */
            tl1.to('.airDropText', {
                duration: 1,
                onComplete: () =>{
                    airDropTextInTween.seek(0.5);
                    airDropTextInTween.pause();
                }
            }, ">");

            tl1.to('.airDropText', {
                duration: 16,
                top: `-20%`,
            }, ">");

            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () => {
                    airDropText1ColorLightTween.play();
                    airDropText2ColorLightTween.reverse();
                },
                onComplete: () =>{
                    airDropText1ColorLightTween.reverse();
                    airDropText2ColorLightTween.play();
                }
            }, "<+=1");
            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () => {
                    airDropText2ColorLightTween.play();
                    airDropText3ColorLightTween.reverse();
                },
                onComplete: () =>{
                    airDropText2ColorLightTween.reverse();
                    airDropText3ColorLightTween.play();
                }
            }, ">+=0.5");
            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () => {
                    airDropText3ColorLightTween.play();
                    airDropText4ColorLightTween.reverse();
                },
                onComplete: () =>{
                    airDropText3ColorLightTween.reverse();
                    airDropText4ColorLightTween.play();
                }
            }, ">+=0.5");
            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () => {
                    airDropText4ColorLightTween.play();
                    airDropText5ColorLightTween.reverse();
                },
                onComplete: () =>{
                    airDropText4ColorLightTween.reverse();
                    airDropText5ColorLightTween.play();
                }
            }, ">+=0.5");
            tl1.to('.airDropText', {
                duration: 1,
                onReverseComplete: () => {
                    airDropTextOutTween.seek(0);
                    airDropTextOutTween.pause();
                },
                onComplete: () =>{
                    airDropTextOutTween.play();
                }
            }, ">+=9.5");

            //空投手机动画第二部分
            tl1.to(airDropPhone2, {
                duration: 10,
                onStart: () =>{
                    airDropPhoneChangeTween.play();
                },
                onReverseComplete: () =>{
                    airDropPhoneChangeTween.reverse();
                },
                onComplete: () =>{
                    airDropTextOutTween.seek(0.5);
                    airDropTextOutTween.pause();
                    //airDropSupportTeamOutTween.seek(0.5);
                    //airDropSupportTeamOutTween.pause();
                }
            }, ">");
            //手机参数显示
            tl1.to('.attr', {duration: 1,
                onStart:()=>{
                    phoneDesTween.restart();
                },
                onReverseComplete:()=>{
                    phoneDesTween.seek(0);
                    phoneDesTween.pause();
                }
            }, "<+=1");

            onClickGoTop();
            
            if(window.history.scrollRestoration) {
                window.history.scrollRestoration = 'manual'
            }
            
            window.onload = (event) => {
                const currentScrollPrg = tl1.scrollTrigger.progress;
                console.log("fully loaded", currentScrollPrg);

                if (currentScrollPrg > 0){
                    //when images fully loaded, refresh timeline in-place
                    const changedPrg = Math.max(0, currentScrollPrg - 0.001);
                    window.scrollTo(0, changedPrg * ScrollTrigger.maxScroll(window, false));
                    tl1.progress(changedPrg);
                }
              };
        },

        { scope: rootRef }
    );

    const onClickGoTop = contextSafe(()=>{

        if (!tl1Ref.current) return;

        const tl = tl1Ref.current;
        const pos = tl.scrollTrigger.labelToScroll("text1");
        
        window.scrollTo(0, pos);
        tl.progress(pos / ScrollTrigger.maxScroll(window, false));

        //console.log('onClickGoTop');
    });

	return(
		<div ref={rootRef} className={`${s.root} textRoot`}>
            <div ref={tlTriggerRef} className={`${s.tlTrigger} tlTrigger`}>
                {/*<div className={s.tlTriggerScroll}>
                </div>*/}
            </div>

            <video className={`${s.video_background} video_section3`} style={{opacity:0, visibility:false}} ref={video_section3_ref} autoPlay muted loop playsInline>
                <source src="./video/World.mp4" type="video/mp4" />
            </video>

            <video className={`${s.video_background} video_section4`} style={{opacity:0, visibility:false}} ref={video_section4_ref} autoPlay muted loop playsInline>
                <source src="./video/Mobile_Video.mp4" type="video/mp4" />
            </video>

            <div className={`${s.phoneExhibitionAnimationRoot} phoneExhibitionAnimationRoot`}>
                <canvas ref={chipCanvasRef} className={`${s.chipAnimation} chipAnimation`}/>
                <canvas ref={phoneExhibitCanvasRef} className={`${s.phoneExhibitionAnimation} phoneExhibitionAnimation`}/>
            </div>
            
            <div>
                <canvas ref={airDropPhone2Ref} className={`${s.airDropPhone2} airDropPhone2`}/>
                <canvas ref={airDropGiftRef} className={`${s.airDropGift} airDropGift`}/>
            </div>

            <div className={`${s.fixedSize43}`}>
                <div className={`${s.phoneText1_clip}`}>
                    <div className={`${s.phoneText1} phoneText1`}>
                        <span className="seq2Text1" style={{fontSize: getTitleTextFontSize(), color: longTextColorGrayLight}}>
                            Web2 Convenience<br/>
                        </span>
                        <span className="seq2Text2" style={{fontSize: getTitleTextFontSize(), color: longTextColorGrayLight}}>
                            Web3 Guarantees
                        </span><br/><br/>
                        <span className="seq2Text3" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            OS level support for headless<br/>wallet signatures,
                        </span>
                        <span className="seq2Text4" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            &nbsp;bringing In<br/>App Purchase alike UX without<br/>unnecessary prompts
                        </span>
                        <span className="seq2Text5" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}> 
                            &nbsp;cross<br/>dApps, crypto wallets, and<br/>tokens of various standards.
                        </span>
                    </div>
                </div>

                <div className={`${s.phoneText2_clip}`}>
                    <div className={`${s.phoneText2} phoneText2`}>
                        <span className="seq3Text1" style={{fontSize: getTitleTextFontSize(), color: longTextColorGrayLight}}>
                            DePIN Kit
                        </span><br/><br/>
                        <span className="seq3Text2" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            Ensures seamless connections<br/>
                        </span>
                        <span className="seq3Text3" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            between native connectivity<br/>modules, processor, and sensors<br/>
                        </span>
                        <span className="seq3Text4" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            with 3rd party DePIN hardware<br/>and applications,
                        </span>
                        <span className="seq3Text5" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            &nbsp;making Alpha<br/>Mobile home for the next io.net<br/>and getgrass.io
                        </span>
                    </div>
                </div>

                <div className={`${s.airDrop_clip}`}>
                    <div ref={airDropTextRootRef} className={`${s.airDropText} airDropText`} style={{visibility:'hidden'}}>
                        <span className="airDropText1" style={{fontSize: getTitleTextFontSize(), color: longTextColorGrayLight}}>
                            Airdrops
                        </span><br/><br/>
                        <span className="airDropText2" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            Get special airdrops form<br/>many of Alpha Mobiles’s<br/>partners,
                        </span>
                        <span className="airDropText3" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            &nbsp;only within Up Store.<br/>
                        </span>
                        <span className="airDropText4" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            Join the fast growing<br/>community now,
                        </span>
                        <span className="airDropText5" style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            &nbsp;and get your<br/>next big alpha.
                        </span>
                        {/*<span ref={airDropText1Ref} style={{fontSize: getBodyTextFontSize(), color: longTextColorGrayLight}}>
                            Use Up Store everyday<br/>and get ready for 
                            <span ref={airDropText1HighLightRef} style={{color: longTextColorGrayLight}}>
                                &nbsp;massive<br/>airdrops&nbsp;
                            </span> 
                            from many part-<br/>ners of Alpha Mobile.
                        </span>*/}
                    </div>
                </div>
            </div>

            <div className={`${s.page3EndTextRoot} page3EndTextRoot`}>
                {/*<div className={`${s.page3EndTextTitle}`} style={{fontSize: getPage3EndTextTitleFontSize()}}>
                    Access 700+ dApps
                </div>

                <div className={`${s.page3EndTextLeftRoot}`}>
                    <div className={`${s.page3EndTextLeftTitle}`} style={{fontSize: getPage3EndTextDesTitleFontSize()}}>
                        Up Store
                    </div>
                    <div className={`${s.page3EndTextLeft}`} style={{fontSize: getPage3EndTextDesFontSize()}}>
                        is a Web3-focused App Store, sidesteps traditional app stores like Apple's App Store or Google Play, which are less accommodating Web3 applications. Up Store 
                        <span style={{color: longTextHighLightColor}}>
                        &nbsp;enables rapid promotions of popular dApps and DePIN hardware&nbsp;
                        </span>
                        directly to the expanding Alpha Mobile community.
                    </div>
                </div>

                <div className={`${s.page3EndTextRightRoot}`}>
                    <div className={`${s.page3EndTextRightTitle}`} style={{fontSize: getPage3EndTextDesTitleFontSize()}}>
                        dApp Dev Kit
                    </div>
                    <div className={`${s.page3EndTextRight}`} style={{fontSize: getPage3EndTextDesFontSize()}}>
                        allows dApps to have easy connection to OS and phone wallet, supporting most wanted features like
                        <span style={{color: longTextHighLightColor}}>
                        &nbsp;dynamic memory and compute power manage,  headless wallet signatures&nbsp;
                        </span>
                        and many more
                    </div>
                </div>*/}
            </div>

            <div className={`${s.buyPhoneRoot}`}>
                <div className={`${s.buyPhoneText} buyPhoneText`}>
                    <div className={s.buyPhoneTitleRoot}>
                        {/*<CustomIcon className={s.alphaLogo} imgName={'Logo_Alpha'} imgType={'png'} width={88} height={88} />*/}
                        <div className={s.buyPhoneTitle} style={{fontSize: getAlphaMobileFontSize()}}>Alpha Mobile</div>
                    </div>
                    <div className={s.buyPhoneDes} style={{fontSize: getAlphaMobileDesFontSize()}}>
                        Web3 optimized smartphone
                    </div>
                </div>
            </div>

            <div className={s.topBarRoot}>
                <CustomIcon className={s.socialIcon} imgName={'UI_Button_White_Twitter_01'} imgType={'png'} width={20} height={20}
                    onClick={()=>{
                        window.open("https://twitter.com/AlphaMobileWeb3");
                    }} />
                <CustomIcon className={s.socialIcon} imgName={'discord-mark-white'} imgType={'png'} width={26.4} height={20} 
                    onClick={()=>{
                        window.open("https://discord.gg/QWcSdPVQsk");
                    }} />
                <div className={s.appButton} style={{marginRight: '10px'}} onClick={()=>{
                    //buyFn();
                }}>
                    Buy
                </div>
            </div>
            <div className={`${s.fixedSize43}`}>
                <div className={`${s.attr} attr`}>
                    <AttrVert/>
                </div>
            </div>
		</div>
	);

}

export default memo(AlphaPhoneVerticalTimeline);

const data = [
    {
        label:'Body',
        children:[
            {
                key:'HEIGHT',
                val:'167.5mm'
            },{
                key:'WIDTH',
                val:'75mm'
            },{
                key:'THICKNESS',
                val:'8.6 mm'
            },{
                key:'WEIGHT',
                val:'233g'
            }
        ]
    },{
        label:'LCD',
        children:[
            {
                key:'RESOLUTION',
                val:'1080 * 2340 ( FHD+ )'
            },{
                key:'LCD Size',
                val:'6.78 in'
            },{
                key:'LCD Type',
                val:'IPS'
            }
        ]
    },{
        label:'Operating System',
        children:[
            {
                key:'OS',
                val:'Android 13'
            }
        ]
    },{
        label:'Battery',
        children:[
            {
                key:'CAPACITY',
                val:'5000mAh'
            },{
                key:'Charger',
                val:'33W 3A'
            }
        ]
    },{
        label:'Memory',
        children:[
            {
                key:'RAM or ROM',
                val:'8GB + 256GB'
            },{
                key:'Memory card type',
                val:'MicroSD'
            }
        ]
    },{
        label:'Camera',
        children:[
            {
                key:'Rear',
                val:'108MP main camera <br/> 16MP ultrawide angle <br/> 2MP macro'
            },{
                key:'Front',
                val:'32MP'
            },{
                key:'Flash light',
                val:'Single rear LED fill light'
            }
        ]
    },{
        label:'Sensor',
        children:[
            {
                key:'G-Sensor',
                val:'supported'
            },{
                key:'L-Sensor',
                val:'supported'
            },{
                key:'P-Sensor',
                val:'supported'
            },{
                key:'M-SENSOR',
                val:'supported'
            },{
                key:'Gyroscope',
                val:'supported'
            },{
                key:'Face recognition',
                val:'supported'
            },{
                key:'NFC',
                val:'supported'
            },{
                key:'Fingerprint',
                val:'supported'
            }
        ]
    },{
        label:'Data Transmission',
        children:[
            {
                key:'GPS',
                val:'supported'
            },{
                key:'WIFI',
                val:'IEEE802.11 a/b/g/n/ac'
            },{
                key:'Bluetooth',
                val:'5.0'
            },{
                key:'FM',
                val:'supported'
            },{
                key:'OTG',
                val:'supported'
            }
        ]
    }
]

export {
    data
}